import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import isoWeek from 'dayjs/plugin/isoWeek';
import { useNavigate, useParams } from 'react-router-dom';
import { useHttpClient } from '../../../../shared/http-client';
import { toast } from 'react-toastify';

export const ArchivosPresupuestoTotalesComponent = () => {
    dayjs.extend(localeData);
    dayjs.extend(weekday);
    dayjs.extend(isoWeek);

    const navigate = useNavigate();
    const { id } = useParams();
    const { api, state } = useHttpClient();
    const [proyecto, setProyecto] = useState<any>();

    useEffect(() => {
        api(`/proyectos/${id}/`, 'GET');
    }, []);

    useEffect(() => {
        if (!state.isLoading) {
            if (state.data) {
                if (state.path.includes('proyectos')) {
                    const proyecto = state.data;
                    console.log('Proyecto', proyecto);
                    setProyecto(proyecto);
                }
            }

            if (state.error) {
                toast.error(state.error.detail);
            }
        }
    }, [state]);

    return (
        <div className="flex h-full flex-col">
            <div className="w-full flex flex-row justify-between items-center bg-gray-100 p-2">
                <div className="text-sm font-bold">Total</div>
                <div className="text-sm">
                    {proyecto?.total_presupuesto.toFixed(2) ?? '-'}
                </div>
            </div>
            <div className="w-full flex flex-row justify-between items-center p-2">
                <div className="text-sm">Despeses general (13%)</div>
                <div className="text-sm">
                    - {proyecto?.despeses_presupuesto.toFixed(2) ?? '-'}
                </div>
            </div>
            <div className="w-full flex flex-row justify-between items-center p-2">
                <div className="text-sm">Benefici industrial (6%)</div>
                <div className="text-sm">
                    - {proyecto?.benefici_presupuesto.toFixed(2) ?? '-'}
                </div>
            </div>
            <div className="w-full flex flex-row justify-between items-center mb-8 bg-gray-100 p-2">
                <div className="text-sm font-bold">Base imposable a l'ICIO</div>
                <div className="text-sm">
                    {proyecto?.base_presupuesto.toFixed(2) ?? '-'}
                </div>
            </div>
        </div>
    );
};
